import React from 'react'
//
import Accordion from '~storybook/accordion'

export const ParagraphAccordion = ({ fieldItems }) => {
  if (!fieldItems) {
    return null
  }

  const items = fieldItems
    ?.map((item) => item.entity)
    ?.map((entity) => {
      return {
        id: entity.id,
        title: entity?.fieldHeading,
        subtitle: entity?.fieldSubHeading,
        text: entity?.fieldText?.processed
      }
    })

  return (
    <section className="bg-white-500 w-full mb-8 relative">
      <Accordion items={items} />
    </section>
  )
}

export default ParagraphAccordion
