import React, { useEffect, useState } from 'react'

import tw, { styled } from 'twin.macro'
//
import Icons from '~storybook/icons/index'
import FormattedText from '~utils/text'
import { Heading } from '~storybook/blocks/typography/index'

const AccordionItemWrapper = styled(({ expanded, ...other }) => <div {...other} />)`
  ${tw`block border-b border-solid border-c-accordion-border pt-8 pb-4`}
  ${({ expanded }) => expanded && tw`pb-6`}
`
const AccordionItem = styled(({ active, ...other }) => <button type="button" {...other} />)`
  ${tw`w-full bg-transparent cursor-pointer transition-colors duration-200 flex justify-between`}
  ${tw`outline-none focus:outline-none no-underline hover:no-underline py-0 mt-0 mb-0`}

  svg {
    width: 48px;
    height: 48px;
  }
`

const Accordion = ({ title = '', subtitle = '', text = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isRendered, setIsRendered] = useState(false)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  if (!isSSR) {
    var _window = window,
        _paq = _window._paq;
        dev = _window.dev;
  }

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <AccordionItemWrapper expanded={isExpanded}>
      <AccordionItem onClick={() => {
        toggleAccordion()
        _paq.push(['trackEvent', 'Accordion', 'Toggle', title]);
      }}>
        <div className="flex flex-col items-start">
          <Heading level="h2" className="text-left">
            {title}
          </Heading>
          {subtitle && (
            <Heading className="text-left mt-2 mb-2" level="h3">
              {subtitle}
            </Heading>
          )}
        </div>

        {isExpanded || !isRendered ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
      </AccordionItem>
      {(isExpanded || !isRendered) && (
        <div className="p-0 m-0 mb-2 md:mb-4 lg:mb-6 p-4 bg-c-accordion-bg text-c-accordion-text">
          <FormattedText text={text} format="html" />
        </div>
      )}
    </AccordionItemWrapper>
  )
}

export const AccordionSet = ({ items }) => {
  return items.map((accordion) => <Accordion key={accordion?.id} {...accordion} />)
}

export default AccordionSet
