import React from 'react'
import IframeResizer from 'iframe-resizer-react'

const ParagraphGroupWebformEmbed = ({ fieldGroupForm = [], language }) => {
  console.log(language)

  const DRUPAL_FORMS_URL = process.env.DRUPAL_FORMS_URL || 'https://content.fed.be'

  return (
    <IframeResizer
      log
      src={`${DRUPAL_FORMS_URL}/${language}/webform/${fieldGroupForm.targetId}/share/iframe-resizer/4.2.10`}
      style={{ width: '1px', minWidth: '100%'}}
      sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox"
    />
  )
}

export default ParagraphGroupWebformEmbed
