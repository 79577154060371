import React, { useState } from 'react'
import tw, { theme, css, styled } from 'twin.macro'
//
import { Heading } from '~storybook/blocks/typography/index'
import { ImageProxy } from '~storybook/image/image'
import { Html } from '~storybook/blocks/html/html'
import NavLink from '~utils/navlink'
import Icons from '~storybook/icons/index'
import Button from '~storybook/button/index'

const ImageCredits = styled.div`
  ${tw`absolute bottom-0 right-0 text-white py-2 px-3 text-sm z-10`}
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${theme`colors['c-timeline-card'].imageCredits`};
    opacity: 1;
    z-index: -1;
  }
`

const TertiaryStyle = styled((props) => <NavLink {...props} />)`
  ${tw`text-c-timeline-card-text text-base font-medium py-0 mb-0 no-underline cursor-pointer transition-colors duration-200 flex justify-center pr-4 justify-start`}
  ${tw`bg-transparent hover:text-c-timeline-card-hoverText hover:underline`}
  svg {
    margin-left: 10px;
    display: inline-block;
  }
`

const CardWrapper = styled.div`
  ${tw`block relative mb-8 text-base font-normal w-full bg-c-timeline-card-bgWrapper text-c-timeline-card-fgWrapper`}

  max-width: 360px;

  @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
    max-width: 380px;
  }

  .gatsby-image-wrapper {
    ${tw`block relative`}

    min-height: 140px;
    height: 140px;

    @media (max-width: ${`${parseInt(`${theme`screens.lg`}`.replace('px', ''), 10) - 1}px`}) {
      min-height: 147px;
      height: 147px;
    }
  }
`

const CardContent = styled(({ children, limitHeight, ...rest }) => <div {...rest}>{children}</div>)`
  ${tw`block relative text-base font-normal w-full p-6 flex flex-col justify-between bg-c-timeline-card-bgContent text-c-timeline-card-fgContent`}

  ${({ limitHeight }) =>
    limitHeight &&
    css`
      max-height: 300px;
      display: block;
      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to bottom, rgba(244, 245, 248, 0) -25%, #f4f5f8 36%);
      }
    `}

  a, button {
    ${tw`text-c-timeline-card-text hover:text-c-timeline-card-hoverText`}
  }
`

export const TimelineCard = ({ image = null, title = '', text = '', cta = null, showCta = true }) => {
  const { length } = `${title} ${text}`
  const shouldShowReadMore = length > 360

  const [showReadMore, setShowReadMore] = useState(shouldShowReadMore)

  const cardContent = (
    <>
      <div>
        {title && <Heading className="mb-4 font-medium" level="h3" text={title} />}
        <div className="mb-6">{text ? <Html value={text} /> : null}</div>
      </div>
      {showCta && cta?.url && cta?.label && (
        <TertiaryStyle to={cta?.url}>
          <div className="block">
            {cta?.label}
            {cta?.external ? <Icons.ExternalLink /> : <Icons.ArrowRight />}
          </div>
        </TertiaryStyle>
      )}
    </>
  )

  const cardTypeContents = (
    <CardWrapper>
      <div className="block relative">
        {image?.url ? (
          <ImageProxy
            objectFit="cover"
            noCrop
            {...{
              ...image,
              type: 'fluid',
              params: {
                mw: 356,
                mh: 225,
                g: 'sm',
                r: 'fit'
              }
            }}
          />
        ) : null}
        {image?.credits && <ImageCredits>{image?.credits}</ImageCredits>}
      </div>

      <CardContent limitHeight={showReadMore}>{cardContent}</CardContent>

      {showReadMore && (
        <div className="absolute bottom-0 p-6">
          <Button
            variant="tertiary"
            className="hover:text-c-timeline-card-hoverText"
            onClick={() => setShowReadMore(false)}
            icon="ChevronDown"
          >
            Read more
          </Button>
        </div>
      )}
    </CardWrapper>
  )

  return cardTypeContents
}

export default TimelineCard
