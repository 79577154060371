import React from 'react'
import tw from 'twin.macro'
//
import ParagraphText from './text'
import ParagraphCardQuote from './card-quote'
import ParagraphCardIcon from './card-icon'
import ParagraphCardImage from './card-image'
import ParagraphCardContact from './card-contact'
import ParagraphHeading from './heading'
import ParagraphVideo from './video'
import ParagraphAccordion from './accordion'
import ParagraphForm from './form'
import ParagraphTimeline from './timeline'
import ParagraphDownloads from './downloads'
import ParagraphTwitterTimeline from './twitter-timeline'
import ParagraphSocialMedia from './social-media'
import ParagraphView from './view'

const mappedParagraphs = {
  Drupal_ParagraphText: ParagraphText,
  Drupal_ParagraphCardQuote: ParagraphCardQuote,
  Drupal_ParagraphCardIcon: ParagraphCardIcon,
  Drupal_ParagraphCardImage: ParagraphCardImage,
  Drupal_ParagraphCardContact: ParagraphCardContact,
  Drupal_ParagraphHeading: ParagraphHeading,
  Drupal_ParagraphVideo: ParagraphVideo,
  Drupal_ParagraphAccordion: ParagraphAccordion,
  Drupal_ParagraphForm: ParagraphForm,
  Drupal_ParagraphTimeline: ParagraphTimeline,
  Drupal_ParagraphDownloads: ParagraphDownloads,
  Drupal_ParagraphTwitterTimeline: ParagraphTwitterTimeline,
  Drupal_ParagraphSocialMedia: ParagraphSocialMedia,
  Drupal_ParagraphView: ParagraphView
}

const ColumnParagraphs = ({ entity, ...rest }) => {
  const paragraphs = entity
    ?.filter((item) => item?.entity?.__typename)
    ?.map((item) => {
      const Paragraph = mappedParagraphs?.[item?.entity?.__typename]
      return <Paragraph key={`cp-${item?.entity?.id}`} {...item?.entity} {...rest} />
    })
  return paragraphs
}

const ParagraphColumnsRow = ({
  fieldColumnItem1Bg,
  fieldColumnItem2Bg,
  fieldColumnItem3Bg,
  fieldColumnRowLayout,
  fieldColumnItem1,
  fieldColumnItem2,
  fieldColumnItem3,
  hasSidebar,
  language
}) => {
  let cols = 1
  if (fieldColumnItem2?.length && fieldColumnItem3?.length) {
    cols = 3
  } else if (fieldColumnItem2?.length) {
    if (!hasSidebar && (fieldColumnRowLayout === '66_33' || fieldColumnRowLayout === '33_66')) {
      cols = 3
    } else {
      cols = 2
    }
  }


  return (
    <section className={`columns_row`} css={[cols > 1 && tw`md:grid gap-4 mt-8`, cols === 2 && tw`grid-cols-2`, cols === 3 && tw`grid-cols-3`]}>
      {fieldColumnItem1?.length ? (
        <div css={[tw`md:pt-0 pr-2`, !hasSidebar && fieldColumnRowLayout === '66_33' && tw`col-span-2`]}>
          <ColumnParagraphs
            entity={fieldColumnItem1}
            isColumnRow
            isSingle={cols === 1}
            bgColorStyle={fieldColumnItem1Bg}
            language={language}
          />
        </div>
      ) : null}

      {fieldColumnItem2?.length ? (
        <div css={[tw`md:pt-0 p-2`, !hasSidebar && fieldColumnRowLayout === '33_66' && tw`col-span-2`]}>
          <ColumnParagraphs
            entity={fieldColumnItem2}
            isColumnRow
            isSingle={cols === 1}
            bgColorStyle={fieldColumnItem2Bg}
            language={language}
          />
        </div>
      ) : null}

      {fieldColumnItem3?.length ? (
        <div css={[tw`md:pt-0 pl-2`]}>
          <ColumnParagraphs
            entity={fieldColumnItem3}
            isColumnRow
            isSingle={cols === 1}
            bgColorStyle={fieldColumnItem3Bg}
            language={language}
          />
        </div>
      ) : null}
    </section>
  )
}

export default ParagraphColumnsRow
