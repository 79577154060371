import React from 'react'
import { graphql } from 'gatsby'
//
import Layout from '~components/layout'
import PageLayout from '~storybook/layout'
import SEO from '~components/seo'
import { ParagraphComponents } from '~utils/components'
import Hero from '~components/hero'

const Page = ({ data, pageContext }) => {
  const { node, group } = data.drupal
  const { nid, language, menus, componentMenus, metatags } = pageContext
  const { fieldParagraphs, fieldDisableLangSwitcher, fieldHero, fieldSidebar, fieldSidebarDisable, uuid } = node
  const langCode = language.toLowerCase()
  const langMenus = menus && menus[langCode] || {}
  const {
    fieldSidebar: fieldSiteSidebar,
    fieldHero: fieldGroupHero,
    fieldAnnouncement,
    fieldMiniGdprAnnouncement,
    fieldGdprTitle,
    fieldGdprDescription,
    fieldGdprCookies = [],
    fieldMetaSiteImage
  } = group

  const sidebarParagraphs = !fieldSidebarDisable
    ? (fieldSidebar?.length && fieldSidebar) || fieldSiteSidebar || null
    : null
  const sidebar = sidebarParagraphs ? (
    <ParagraphComponents paragraphs={sidebarParagraphs} language={langCode} componentMenus={componentMenus} isSidebar />
  ) : null
  const pageHero = fieldHero.length ? fieldHero : fieldGroupHero

  return (
    <Layout
      language={langCode}
      disableLangSwitcher={fieldDisableLangSwitcher}
      menus={langMenus}
      announcement={fieldAnnouncement}
      miniGdprAnnouncement={fieldMiniGdprAnnouncement}
      groupCss={group.fieldCss}
      nodeCss={node.fieldCss}
      cookiesInfo={{ fieldGdprTitle, fieldGdprDescription, fieldGdprCookies }}
      pageID={uuid}
    >
      <SEO
        key={`page-${nid}-${langCode}`}
        group={group}
        title={node.fieldPublicPageTitle}
        metatags={metatags}
        globalImage={fieldMetaSiteImage?.entity?.thumbnail?.url}
        lang={langCode}
        nid={nid}
        gid={group.id}
        langSlugs={pageContext.langSlugs}
      />

      <Hero items={pageHero?.map((entity) => entity?.entity)} />

      <PageLayout sidebar={sidebar} order="first">
        <ParagraphComponents paragraphs={fieldParagraphs} language={langCode} hasSidebar={!!sidebar} componentMenus={componentMenus} />
      </PageLayout>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($gid: String!, $nid: String!, $language: Drupal_LanguageId!) {
    drupal {
      node: nodeById(id: $nid, language: $language) {
        title
        uuid
        path {
          alias
        }
        entityId
        langcode {
          value
        }
        ... on Drupal_NodePage {
          title
          fieldCss
          fieldDisableLangSwitcher
          fieldPublicPageTitle
          fieldParagraphs {
            ...FragmentFieldParagraphs
          }
          fieldSidebarDisable
          fieldSidebar {
            ...FragmentFieldSidebarParagraphs
          }
          fieldHero {
            entity {
              ... on Drupal_ParagraphHeroItem {
                ...FragmentParagraphHeroItem
              }
            }
          }
        }
      }
      group: groupById(id: $gid, language: $language) {
        ...FragmentSite
      }
    }
  }
`
